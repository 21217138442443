import { useCallback, useEffect, useState } from 'react';

import { useStack9 } from '@april9/stack9-react';
import { notification } from 'antd';

import { StoreLocationItemType } from '../../models/eagers-api/storedetails';
import { SearchResponse } from '../../models/opensearch/lib';

export const useOsStoreLocation = (siteCode: string | undefined) => {
  const [data, setData] = useState<StoreLocationItemType | null>(null);
  const { queryService } = useStack9();

  const fetchStoreLocation = useCallback(async () => {
    try {
      if (!siteCode) {
        throw new Error('Site code is required.');
      }

      const response = await queryService.runNamedQuery<
        SearchResponse<StoreLocationItemType>
      >('store_location_detail', 'searchOpensearchIndex', {
        vars: {
          page: 0,
          limit: 1,
          index_name: 'storedetails',
          _source: [],
          query: JSON.stringify({
            match: { siteCode: { query: siteCode } },
          }),
          aggs: JSON.stringify({}),
          sort: [],
          highlight: JSON.stringify({}),
        },
      });

      const locationItem = response?.data?.hits?.hits?.[0]?._source ?? null;
      if (locationItem) {
        setData(locationItem);
      } else {
        throw new Error('Record could not be retrieved from Opensearch index.');
      }
    } catch (e: any) {
      notification.error({
        message: `Error getting store location record: ${e?.message || ''}`,
      });
    }
  }, [queryService, siteCode]);

  useEffect(() => {
    fetchStoreLocation();
  }, [fetchStoreLocation]);

  return { storeLocation: data };
};
