import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRouteAndQueryParams, useStack9 } from '@april9/stack9-react';
import { Icon, JsonInput, S9Button, useAuth } from '@april9/stack9-ui';
import { notification, Popconfirm } from 'antd';
import { Record } from 'runtypes';

import BackButton from '../components/BackButton';
import { useOsVehicle } from '../hooks/inventory/vehicle';

const Button = S9Button.default;

export const VehicleDetailPage = () => {
  const {
    routeParams: { vin },
  } = useRouteAndQueryParams();
  const navigate = useNavigate();

  const { queryService } = useStack9();
  const { user } = useAuth();

  const [isDeleting, setIsDeleting] = useState(false);

  const { vehicle } = useOsVehicle(vin);

  const onDeleteInventory = async () => {
    setIsDeleting(true);
    try {
      await queryService.runNamedQuery(
        'vehicle_detail',
        'deleteOpensearchDocs',
        {
          vars: {
            index_name: 'inventory',
            query: JSON.stringify({
              bool: {
                must: {
                  terms: {
                    vehicleId: [vehicle?.vehicleId],
                  },
                },
              },
            }),
          },
        },
      );

      notification.success({
        message: 'Vehicle deleted successfully.',
      });

      navigate(0);
    } catch (e: any) {
      notification.error({
        message: `Error deleting vehicle: ${e?.message || ''}`,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div className="max-w-screen-lg mx-auto py-6 px-12">
      <div className="flex items-center gap-x-4 my-4">
        <BackButton />

        <h1 className="font-bold text-2xl grow text-center">
          {vehicle?.metadata?.displayVehicleText || 'No vehicle found.'}
        </h1>

        {user?.is_administrator && vehicle && (
          <Popconfirm
            title={
              <div>
                <div>Are you sure you want to delete this record?</div>
                <p>
                  Deleting this record will also remove it easyauto123 shop
                  page.
                </p>
              </div>
            }
            onConfirm={onDeleteInventory}
            icon={
              <Icon
                icon="WarningOutlined"
                className="mr-2"
                style={{ color: 'red' }}
              />
            }
            placement="bottomRight"
          >
            <Button
              label="Delete"
              htmlType="button"
              className="font-bold"
              danger
              disabled={isDeleting}
              loading={isDeleting}
            />
          </Popconfirm>
        )}
      </div>

      {vehicle && (
        <JsonInput height={600} schema={Record({})} value={vehicle} />
      )}
    </div>
  );
};
