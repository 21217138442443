import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';

import * as ui from '@april9/stack9-ui';
import { CancelJobRequestButton } from '@april9/stack9-ui';

export const ActionBarComponent = memo(
  ({ defaultActions }: ui.ActionBarComponentProps) => (
    <>
      <Routes>
        <Route path="/job-request">
          {/* don't remove the empty route, it is intentional */}
          <Route path="list" element={<></>} />
          <Route path=":id" element={<CancelJobRequestButton />} />
        </Route>
      </Routes>
      {defaultActions}
    </>
  ),
);
