import { useCallback, useEffect, useState } from 'react';

import { useStack9 } from '@april9/stack9-react';
import { notification } from 'antd';

import { OSVehicleType } from '../../models/opensearch/inventory';
import { SearchResponse } from '../../models/opensearch/lib';

export const useOsVehicle = (vin: string | undefined) => {
  const [vehicle, setVehicle] = useState<OSVehicleType | null>(null);
  const { queryService } = useStack9();

  const fetchVehicle = useCallback(async () => {
    try {
      if (!vin) {
        throw new Error('VIN is required.');
      }

      const response = await queryService.runNamedQuery<
        SearchResponse<OSVehicleType>
      >('vehicle_detail', 'searchOpensearchIndex', {
        vars: {
          page: 0,
          limit: 1,
          index_name: 'inventory',
          _source: [],
          query: JSON.stringify({
            bool: { must: [{ match: { 'vehicleDetails.vin': vin } }] },
          }),
          aggs: JSON.stringify({}),
          sort: [],
          highlight: JSON.stringify({}),
        },
      });

      const vehicle = response?.data?.hits?.hits?.[0]?._source ?? null;
      if (vehicle) {
        setVehicle(vehicle);
      } else {
        throw new Error(
          'Vehicle could not be retrieved from Opensearch index.',
        );
      }
    } catch (e: any) {
      notification.error({
        message: `Error getting vehicle: ${e?.message || ''}`,
      });
    }
  }, [queryService, vin]);

  useEffect(() => {
    fetchVehicle();
  }, [fetchVehicle]);

  return { vehicle };
};
