/* eslint-disable import/no-extraneous-dependencies */
import { createRoot } from 'react-dom/client';

import { AppProvider, AuthProvider } from '@april9/stack9-react';
import {
  Alert,
  App,
  AppContextProvider,
  Avatar,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Calendar,
  Card,
  Collapse,
  CollapsePanel,
  Container,
  DescriptionItem,
  Descriptions,
  Empty,
  Image,
  LayoutContent,
  LayoutFooter,
  LayoutHeader,
  LayoutSider,
  Menu,
  Modal,
  Pagination,
  Paragraph,
  PrivilegiesMatrix,
  Progress,
  RadioGroup,
  Rate,
  S9Button,
  S9Checkbox,
  S9Col,
  S9CustomEntityFormPage,
  S9DateField,
  S9EntityActions,
  S9EntityWorkflowActions,
  S9EntityWorkflowHistory,
  S9Fieldset,
  S9FileField,
  S9Form,
  S9Grid,
  S9HTMLField,
  S9Layout,
  S9MonacoEditorField,
  S9MultiDropDown,
  S9NumericField,
  S9OptionSet,
  S9PageContent,
  S9PageHeader,
  S9PageTitle,
  S9Row,
  S9SingleDropDown,
  S9Table,
  S9Tabs,
  S9TextField,
  SaveFilterButton,
  Select,
  Skeleton,
  Spin,
  Statistic,
  Step,
  Steps,
  Switch,
  TabPane,
  TimePicker,
  Timeline,
  TimelineItem,
  UIProvider,
  axiosProvider,
  reportWebVitals,
  stack9Config,
  tokenProvider,
} from '@april9/stack9-ui';
import * as emailModule from '@april9au/stack9-email-ui';
import * as mktModule from '@april9au/stack9-marketing-ui';
import { ConfigProvider } from 'antd';

import { ActionBarComponent } from './ActionBarComponent';
import { StoreLocationDetailPage } from './pages/store-location-detail';
import { VehicleDetailPage } from './pages/vehicle-detail';

// TODO: I would like to move this to stack9-ui/src/components/S9MonacoEditorField.tsx but the build must be adapted.
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/search/match-highlighter';
import 'codemirror/mode/handlebars/handlebars';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/sql/sql';

import './App.css';

const components = [
  Alert,
  Avatar,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Calendar,
  Card,
  Collapse,
  CollapsePanel,
  Container,
  DescriptionItem,
  Descriptions,
  Empty,
  Image,
  LayoutContent,
  LayoutFooter,
  LayoutHeader,
  LayoutSider,
  Menu,
  Modal,
  Pagination,
  Paragraph,
  PrivilegiesMatrix,
  Progress,
  RadioGroup,
  Rate,
  S9Button,
  S9Checkbox,
  S9Col,
  S9CustomEntityFormPage,
  S9DateField,
  S9EntityActions,
  S9EntityWorkflowActions,
  S9EntityWorkflowHistory,
  S9Fieldset,
  S9FileField,
  S9Form,
  S9Grid,
  S9HTMLField,
  S9Layout,
  S9MonacoEditorField,
  S9MultiDropDown,
  S9NumericField,
  S9OptionSet,
  S9PageContent,
  S9PageHeader,
  S9PageTitle,
  S9Row,
  S9SingleDropDown,
  S9Table,
  S9Tabs,
  S9TextField,
  SaveFilterButton,
  Select,
  Skeleton,
  Spin,
  Statistic,
  Step,
  Steps,
  Switch,
  TabPane,
  Timeline,
  TimelineItem,
  TimePicker,
  ...emailModule.components,
  ...mktModule.components,
];

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AppProvider axiosFactory={axiosProvider} config={stack9Config}>
    <AuthProvider
      clientId="" // backoffice does not require clientId
      token={tokenProvider}
      onError={err => {
        console.error(err);
      }}
    >
      <UIProvider components={components} extensions={{ ActionBarComponent }}>
        <AppContextProvider>
          <ConfigProvider>
            <App
              customRoutes={[
                {
                  route: 'vehicle/:vin',
                  component: <VehicleDetailPage />,
                },
                {
                  route: 'store-location/:siteCode',
                  component: <StoreLocationDetailPage />,
                },
                ...emailModule.customRoutes,
                ...mktModule.customRoutes,
              ]}
            />
          </ConfigProvider>
        </AppContextProvider>
      </UIProvider>
    </AuthProvider>
  </AppProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
