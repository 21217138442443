import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'antd';

const BackButton: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Button
      icon={<>←</>}
      onClick={handleClick}
      type="default"
      size="large"
      className="px-2 font-bold"
    >
      Back
    </Button>
  );
};

export default BackButton;
