import { useRouteAndQueryParams } from '@april9/stack9-react';
import { JsonInput } from '@april9/stack9-ui';
import { Record } from 'runtypes';

import BackButton from '../components/BackButton';
import { useOsStoreLocation } from '../hooks/store/store-locations';

export const StoreLocationDetailPage = () => {
  const {
    routeParams: { siteCode },
  } = useRouteAndQueryParams();

  const { storeLocation } = useOsStoreLocation(siteCode);

  return (
    <div className="max-w-screen-lg mx-auto py-6 px-12">
      <div className="flex items-center gap-x-4 my-4">
        <BackButton />

        <h1 className="font-bold text-2xl grow text-center">
          {storeLocation?.name || 'No record found.'}
        </h1>
      </div>

      {storeLocation && (
        <JsonInput height={600} schema={Record({})} value={storeLocation} />
      )}
    </div>
  );
};
